<template>
  <div class="allVideoDetail content">
    <el-form
      :model="ruleForm"
      ref="ruleForm"
      label-width="100px"
      class="demo-ruleForm"
    >
      <el-form-item label="申请类型：">
        <el-select v-model="ruleForm.typecode" placeholder="请选择申请类型">
          <el-option
            :label="item.name"
            :value="item.code"
            v-for="item in typeData"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="用途说明：">
        <el-input
          type="textarea"
          v-model="ruleForm.memo"
          :rows="3"
          placeholder="描述视频的用途，如果是短期使用，则说清楚视频使用的时间"
        ></el-input>
      </el-form-item>
      <el-form-item label="申请人：">
        <el-input v-model="ruleForm.userAccount" readonly></el-input>
      </el-form-item>
      <div>
        <span class="tips">说明：</span
        >如果为短期可查看申请或者“二维码申请”则需要在
        用途说明中描述清楚视频可看时间！
      </div>
      <div class="button" @click="submit">保存</div>
    </el-form>
  </div>
</template>
<script>
export default {
  name: "allVideoDetail",
  props: {
    popupData: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      ruleForm: {
        typecode: "",
        memo: "",
        userAccount: "",
        fkVideoid: ""
      },
      typeData: []
    };
  },
  mounted() {
    const userAccount = sessionStorage.getItem("userAccount");
    this.ruleForm.userAccount = userAccount;
    this.ruleForm.fkVideoid = this.popupData.pkId;
    this.findByDomainid();
  },
  methods: {
    findByDomainid() {
      const params = {
        domainid: "808"
      };
      this.$api("common.domain")
        .invoke(params)
        .then(({ data }) => {
          if (data.code === 1) {
            if (data.data) {
              this.typeData = data.data;
            }
          }
        })
        .catch(() => {});
    },
    submit() {
      this.$api("videoApproval.insertVideoApply")
        .invoke(this.ruleForm)
        .then(({ data: { code, data } }) => {
          console.log("data", data);
          if (code) {
            this.$message({
              showClose: true,
              message: data.msg,
              type: "success",
              duration: 1000
            });
            this.$emit("dialogClose");
          } else {
            this.$message({
              showClose: true,
              message: data.msg,
              type: "error",
              duration: 1000
            });
          }
        });
    }
  }
};
</script>
<style scoped lang="less">
.content {
  padding: 12px;
  .tips {
    color: #f00f00;
    padding-left: 32px;
    margin-bottom: 15px;
  }
  .button {
    display: inline-block;
    color: #fff;
    background-color: #409eff;
    border-color: #409eff;
    padding: 10px 50px;
    border-radius: 3px;
    margin-bottom: 30px;
    margin-top: 20px;
    margin-left: 50%;
    transform: translateX(-50%);
    cursor: pointer;
  }
}
</style>
