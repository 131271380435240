<template>
  <div class="processed content">
    <div class="contentbox">
      <el-container>
        <el-scrollbar style="min-width: 300px !important; height: 100%">
          <el-aside style="margin-right: 0 !important">
            <div class="tree-container">
              <el-tree
                class="tree filter-tree"
                :data="dataRiver"
                :props="defaultProps"
                :filter-node-method="filterNode"
                :expand-on-click-node="false"
                :default-expanded-keys="defaultExpaneded"
                :highlight-current="true"
                :row-key="id"
                :node-key="id"
                :current-node-key="currentNodekey"
                @node-click="handleNodeClick"
                ref="treeRiver"
              >
                <span slot-scope="{ node, data }">
                  <i v-if="data.children" class="el-icon-folder"></i>
                  <!-- <i v-else class="el-icon-collection-tag"></i> -->
                  <i v-else class="el-icon-tickets"></i>
                  <span>{{ node.label }}</span>
                </span>
              </el-tree>
              <div class="p-t-10"></div>
            </div>
          </el-aside>
        </el-scrollbar>
        <el-scrollbar class="wfull">
          <el-main>
            <div ref="rightDevW" class="rightBox">
              <TableQuery
                :formList="formList"
                :formInline="formInline"
                :customArrKey="customArrKey"
                :btnList="btnList"
                :property="queryProperty"
                @btnClickFun="btnClickFun"
                @moveQueryFun="queryBackFun"
                @formDataFun="formDataFun"
              >
              </TableQuery>
              <div class="p-t-10"></div>
              <div ref="tableBox">
                <SuperTable
                  ref="mytable"
                  :tableData="tableData"
                  :loading="tableLoading"
                  :property="tableProperty"
                  :pageData="pageData"
                  @handleCurrentChange="handleCurrentChange"
                  @handleSizeChange="handleSizeChange"
                  @operationClick="operationClick"
                >
                  <template v-slot:result="slotProps">
                    <div
                      class="container red"
                      v-if="slotProps.props.result == '1'"
                    >
                      审核不通过
                    </div>
                    <div
                      class="container green"
                      v-else-if="slotProps.props.result == '2'"
                    >
                      审核通过
                    </div>
                    <div class="container" v-else>待审核</div>
                  </template>
                </SuperTable>
              </div>
            </div>
          </el-main>
        </el-scrollbar>
      </el-container>
      <el-dialog
        :title="title"
        :visible.sync="dialogVisible"
        :append-to-body="true"
        v-if="dialogVisible"
        :before-close="dialogClose"
        width="960px"
      >
        <component
          :is="component"
          :popupData="popupData"
          @dialogClose="dialogClose"
        ></component>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import configBasics from "@/utils/configBasics";
import TableQuery from "@/common/module/TableQuery";
import SuperTable from "@/common/module/SuperTable";
import allVideoDetail from "./allVideoDetail";
import processedDetail from "./processedDetail";
export default {
  name: "processed",
  components: {
    TableQuery,
    SuperTable,
    allVideoDetail,
    processedDetail
  },
  data() {
    return {
      ops: configBasics.vueScrollOps,
      formList: [
        {
          clearable: true,
          datetype: "datetime",
          endplaceholder: "请选择结束值",
          format1: "yyyy-MM-dd HH:mm:ss",
          format: "yyyy-MM-dd HH:mm:ss",
          showhide: true,
          label: "申请时间：",
          valueformat1: "yyyy-MM-dd HH:mm:ss",
          type: "DatePickerScope",
          startname: "starttime",
          startplaceholder: "请选择起始值",
          characteristic: "26196aa2-8223-4271-9f6c-4718dwsdssdsd",
          valueformat: "yyyy-MM-dd HH:mm:ss",
          pickeroptionsend:
            "pickerOptionsEnd26196aa2-8223-4271-9f6c-4718dwsdssdsd",
          name: "choosetime",
          width: "530px",
          endname: "endtime",
          disabled: false,
          control: true,
          noDisabledDate: true
        },
        {
          name: "applytypecode",
          label: "申请类型",
          showhide: true,
          type: "selectst",
          clearable: true,
          placeholder: "请选择申请类型",
          option: []
        },
        {
          name: "result",
          label: "审核结果",
          showhide: true,
          type: "selectst",
          clearable: true,
          placeholder: "请选择申请结果",
          option: [
            {
              labelname: "全部",
              value: ""
            },
            {
              labelname: "审核通过",
              value: "2"
            },
            {
              labelname: "审核不通过",
              value: "1"
            }
          ]
        },
        {
          name: "videoname",
          label: "视频名称",
          showhide: true,
          type: "text",
          clearable: true,
          placeholder: "请输入视频名称"
        }
      ],
      formInline: {
        choosetime: ["", ""],
        applytypecode: "",
        result: "",
        videoname: ""
      },
      customArrKey: ["choosetime"],
      btnList: [
        {
          name: "searchButton",
          icon: "icon-tianjia",
          label: "查询",
          type: "searchButton"
        }
      ],
      queryProperty: {
        labelWidth: "120px",
        typeName: "cedian",
        moveLabelWidth: "120px"
      },
      /* 表格数据 */
      tableData: {
        listData: [],
        titleData: [],
        btnData: []
      },
      /* 表格加载 */
      tableLoading: false,
      /* 表格属性 */
      tableProperty: {
        typeName: "",
        emptyText: "",
        fixheight: false,
        isOrder: true, // 设置表格有序号
        // border: true, // 设置表格有边框
        operationWidth: "140px"
      },
      /* 分页 */
      pageData: {
        currentPage: 1,
        pageSizes: [10, 20, 30, 40, 50],
        pageSize: 10,
        layout: "total, sizes, prev, pager, next, jumper",
        total: 0
      },
      loading: false,
      btnAuthority: false,
      popupData: {},
      dialogVisible: false,
      title: "",
      component: "",
      dataRiver: [],
      defaultExpaneded: [], //默认展开的节点
      defaultProps: {
        children: "nodes",
        label: "text"
      },
      id: "typeCode",
      typecode: "",
      currentNodekey: "" //默认选中的节点树
    };
  },
  async created() {
    this.$api("videoManage.getVideoTypeTree")
      .invoke()
      .then(({ data: { data } }) => {
        this.dataRiver = [{ typeCode: "", text: "全部", nodes: data.data }];
        this.defaultExpaneded = [this.dataRiver[0].nodes[0].typeCode];
        this.defaultExpaneded = [""];
        this.currentNodekey = "";
        this.$nextTick(() => {
          this.$refs.treeRiver.setCurrentKey(this.currentNodekey); //一定要加这个选中了否则样式没有出来
        });
      });
    this.findByDomainid();
    this.resetArr();
    this.getTableList(true); // 表格列表数据
  },
  methods: {
    findByDomainid() {
      const params = {
        domainid: "808"
      };
      this.$api("common.domain")
        .invoke(params)
        .then(({ data }) => {
          if (data.code === 1) {
            if (data.data) {
              data.data.map(item => {
                item.labelname = item.name;
                item.value = item.code;
              });
              data.data.unshift({
                labelname: "全部",
                value: ""
              });
              this.formList.map(item => {
                if (item.name === "applytypecode") {
                  item.option = data.data;
                }
              });
            }
          }
        })
        .catch(() => {});
    },
    resetArr() {
      this.tableData.titleData = [
        {
          headeralign: "center",
          prop: "applytime",
          showoverflowtooltip: true,
          showhide: true,
          label: "申请时间",
          align: "center"
        },
        {
          headeralign: "center",
          prop: "applytypename",
          showoverflowtooltip: true,
          showhide: true,
          label: "申请类型",
          align: "center"
        },
        {
          headeralign: "center",
          prop: "typename",
          showoverflowtooltip: true,
          showhide: true,
          label: "视频类型",
          align: "center"
        },
        {
          headeralign: "center",
          prop: "videoname",
          showoverflowtooltip: true,
          showhide: true,
          label: "视频名称",
          align: "center"
        },
        {
          headeralign: "center",
          prop: "checkusername",
          showoverflowtooltip: true,
          showhide: true,
          label: "审核人",
          align: "center"
        },
        {
          headeralign: "center",
          prop: "checktime",
          showoverflowtooltip: true,
          showhide: true,
          label: "审核时间",
          align: "center"
        },
        {
          headeralign: "center",
          name: "result",
          showoverflowtooltip: true,
          showhide: true,
          type: "slot",
          label: "审核结果",
          align: "center"
        }
      ];
      this.btnAuthority =
        this.$store.state.operationPower.edit.SHJ_ControllerUnit;
      if (this.btnAuthority) {
        this.btnList = [
          {
            name: "searchButton",
            icon: "icon-tianjia",
            label: "查询",
            type: "searchButton"
          }
        ];
        this.tableData.btnData = [
          {
            name: "detailsButton",
            icon: "iconxiangqing1",
            label: "详情",
            type: "detailsButton"
          }
        ];
      } else {
        this.btnList = [
          {
            name: "searchButton",
            icon: "icon-tianjia",
            label: "查询",
            type: "searchButton"
          }
        ];
        this.tableData.btnData = [
          {
            name: "detailsButton",
            icon: "iconxiangqing1",
            label: "详情",
            type: "detailsButton"
          }
        ];
      }
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },
    handleNodeClick(data) {
      // console.log(data);
      this.currentNodekey = data.typeCode;
      this.typecode = data.typeCode;
      this.pageData.currentPage = 1;
      this.getTableList();
    },
    dialogClose() {
      this.dialogVisible = false;
    },
    getTableList() {
      let obj = {
        videoname: this.formInline.videoname,
        applytypecode: this.formInline.applytypecode,
        typecode: this.typecode,
        begintime: this.formInline.choosetime[0] || "",
        endtime: this.formInline.choosetime[1] || "",
        page: this.pageData.currentPage,
        rows: this.pageData.pageSize,
        status: "2" // 0是待处理，2是已处理
      };
      this.$api("videoApproval.getVideoApplyList")
        .invoke(obj)
        .then(({ data: { code, data } }) => {
          if (code === 1) {
            if (data) {
              this.tableData.listData = data.data;
              this.pageData.total = data.total;
            }
          } else {
            this.$message({
              showClose: true,
              message: data,
              type: "error",
              duration: 1000
            });
          }
        });
    },
    /* 当前页-改变事件 */
    handleCurrentChange(val) {
      this.pageData.currentPage = val;
      this.getTableList();
    },
    /* 每页多少条-改变事件 */
    handleSizeChange(val) {
      this.pageData.currentPage = 1;
      this.pageData.pageSize = val;
      this.getTableList();
    },
    // 表格列表上的点击事件
    operationClick(item, scope) {
      if (item.name === "detailsButton") {
        this.dialogVisible = true;
        this.title = "申请详情";
        this.popupData = {
          pkId: scope.row.pkId
        };
        this.component = "processedDetail";
      }
    },
    // 按钮点击事件
    btnClickFun(item) {
      if (item.name === "searchButton") {
        // 查询
        this.pageData.currentPage = 1;
        this.getTableList();
      }
    },
    // 回车查询
    queryBackFun(formInline) {
      this.formInline = formInline;
      // 查询
      this.pageData.currentPage = 1;
      this.getTableList();
    },
    // 查询标签点击事件
    formDataFun(formInline) {
      this.formInline = formInline;
    }
  }
};
</script>
<style scoped lang="less">
.processed {
  /deep/.el-container {
    .wfull {
      width: 100%;
    }
  }
  .container {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    line-height: 44px;
    font-size: 14px;
    &.red {
      background: #f40000;
      color: #fff;
    }
    &.green {
      background: #50cc21;
      color: #fff;
    }
  }
}
</style>
