<template>
  <div class="processedDetail content">
    <p class="title"><span class="sign"></span>申请信息</p>
    <div class="itemBox">
      <div class="itemCon">
        <p class="label">申请类型：</p>
        <p>{{ detailData.applytypename }}</p>
      </div>
      <div class="itemCon">
        <p class="label">用途说明：</p>
        <p>{{ detailData.applymemo }}</p>
      </div>
      <div class="itemCon">
        <p class="label">申请人：</p>
        <p>{{ detailData.applyusername }}</p>
      </div>
      <div class="itemCon">
        <p class="label">申请时间：</p>
        <p>{{ detailData.applytime }}</p>
      </div>
      <div class="button pass" v-if="detailData.result == 2">审核通过</div>
      <div class="button noPass" v-else>审核不通过</div>
    </div>
    <p class="title"><span class="sign"></span>结果</p>
    <div class="itemBox">
      <div class="itemCon">
        <p class="label">视频名称：</p>
        <p>{{ detailData.videoname }}</p>
      </div>
      <div class="itemCon">
        <p class="label">视频类型：</p>
        <p>{{ detailData.typename }}</p>
      </div>
      <div class="itemCon" v-if="!detailData.filecontent">
        <p class="label">有效时间：</p>
        <p class="red">长期有效</p>
      </div>
      <div class="itemCon" v-if="detailData.filecontent">
        <p class="label">有效时间：</p>
        <p class="red">{{ detailData.begintime }}至{{ detailData.endtime }}</p>
      </div>
      <div class="itemCon" v-if="detailData.filecontent">
        <p class="label">二维码图片：</p>
        <div class="qrcode">
          <img :src="detailData.filecontent" />
          <p class="download">点击下载二维码图片</p>
        </div>
      </div>
    </div>
    <p class="title"><span class="sign"></span>审核信息</p>
    <div class="itemBox">
      <div class="itemCon">
        <p class="label">审核人：</p>
        <p>{{ detailData.checkusername }}</p>
      </div>
      <div class="itemCon">
        <p class="label">审核时间：</p>
        <p>{{ detailData.checktime }}</p>
      </div>
      <div class="itemCon">
        <p class="label">说明：</p>
        <p>{{ detailData.checkview }}</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "processedDetail",
  props: {
    popupData: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      detailData: {}
    };
  },

  mounted() {
    console.log("this.popupData", this.popupData);
    // 直接授权详情
    if (this.popupData.pkId) {
      this.getUserApplyById();
    }
  },
  methods: {
    getUserApplyById() {
      const params = {
        pkId: this.popupData.pkId
      };
      this.$api("videoManage.getUserApplyById")
        .invoke(params)
        .then(({ data: { data } }) => {
          this.detailData = data;
          console.log("detailData", this.detailData);
        });
    }
  }
};
</script>
<style scoped lang="less">
.processedDetail {
  padding: 12px;
  .title {
    color: #6294ff;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    .sign {
      flex-shrink: 0;
      width: 0;
      height: 0;
      padding: 8px 2px;
      border-radius: 1px;
      margin-right: 8px;
      background: #6294ff;
    }
  }
  .itemBox {
    background: #f2f2f2;
    padding: 12px;
    margin-bottom: 15px;
    position: relative;
    .itemCon {
      display: flex;
      align-items: center;
      margin-bottom: 12px;
      .label {
        width: 115px;
        text-align: right;
      }
      .qrcode {
        display: flex;
        align-items: center;
        flex-flow: column;
        img {
          width: 100px;
          height: 100px;
        }
        .download {
          cursor: pointer;
        }
      }
    }
    .button {
      position: absolute;
      right: 0;
      bottom: 0;
      padding: 10px 16px;
      border-radius: 3px;
      font-size: 14px;
    }
    .qrcode {
      // position: absolute;
      // right: 20px;
      // bottom: 7px;
      // display: flex;
      // flex-flow: column;
      // align-items: center;
      // img {
      //   width: 100px;
      //   height: 100px;
      // }
      // .download {
      //   cursor: pointer;
      // }
    }
    .wait {
      border: 1px solid #333333;
      color: #333333;
    }
    .pass {
      border: 1px solid #3fcc00;
      color: #3fcc00;
    }
    .noPass {
      border: 1px solid #f40000;
      color: #f40000;
    }
    .red {
      color: #f00f00;
      font-size: 14px;
    }
  }
}
</style>
